/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    selectedKey: {},
    list: [],
    filters: {
      subAccount: '',
      env: '',
    },
    loading: false,
    paginationIndex: 0,
  },

  getters: {
    getList: st => st.list,
    getSelectedKey: st => st.selectedKey,
    getFilters: st => st.filters,
    getLoading: st => st.loading,
    getIndex: st => st.paginationIndex,
  },

  mutations: {
    SET_SELECTED(state, payload) {
      state.selectedKey = payload;
    },

    SET_SDK_API_LIST(state, list) {
      // Update SDK API list
      state.list = list;
    },

    SET_FILTERS(state, payload) {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },

    SET_LOADING(state, bool) {
      state.loading = bool;
    },

    SET_PAGINATION_INDEX(state, index = 0) {
      state.paginationIndex = index;
    },
  },

  actions,
};
