//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import envOptions from '../../../json/envOptions.json';
import { mapGetters } from 'vuex';

export default {
  name: 'Table',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Array,
      default: () => [],
    }
  },

  data() {
    return {
      envOptions,
      loading: false,
    };
  },

  methods: {
    ...mapGetters({
      loading: 'auth_sdk/getLoading',
    }),
    handleCommand(c) {
      switch (c.command) {
      case 'EDIT':
        this.$emit('edit', c.content);
        break;
      case 'ENABLE':
        this.$emit('update-status', c.content);
        break;
      case 'DELETE':
        this.$emit('delete', c.content);
        break;
      default:
        break;
      }
    },
  },
};
