//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
export default {
  name: 'SdkModal',

  props: {
    defaultFormValue: {
      type: Object,
      default: () => {},
    },

    envOptions: {
      type: Array,
      default: () => [],
    },

    subAccountList: {
      type: Array,
      default: () => [],
    },

    whVisible: {
      type: Boolean,
      default: false,
    }

  },

  data() {
    const self = this;

    return {
      formType: 'CREATE', // or EDIT

      emptyForm: {
        subAccountUid: '',
        env: '',
        domain: '',
      },

      form: {},

      formRules: {
        subAccountUid: [
          { required: true, message: self.$t('validations.select', { value: self.$t('fields.subaccount') }), trigger: 'change' },
        ],

        env: [
          { required: true, message: self.$t('validations.select', { value: self.$t('auth_sdk.modal[4]') }), trigger: 'change' },
        ],

        domain: [
          { required: true, message: self.$t('validations.required', { value: self.$t('auth_sdk.modal[5]') }), trigger: 'change' },
          { type: 'url', message: self.$t('validations.valid', { value: self.$t('auth_sdk.modal[5]') }), trigger: 'change' },
        ],
      },
    };
  },

  methods: {
    ...mapGetters({
      loading: 'auth_sdk/getLoading',
    }),
    modalTitle() {
      return this.$t('auth_sdk.header.new_sdk');
      // return this.formType === 'CREATE' ? this.$t('webhooks.modal[13]') : this.$t('webhooks.modal[14]');
    },

    closeModal() {
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.$emit('close-modal');
    },

    resetForm() {
      this.formType = Object.keys(this.defaultFormValue || {}).length ? 'EDIT' : 'CREATE';

      // Set form default to empty
      if (this.formType === 'CREATE') {
        this.form = { ...this.form, ...this.emptyForm };
      } else { // Set form to defaultFormValue
        this.form = { ...this.defaultFormValue };
      }

      // Remove validation messages after setting form datas
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
    },

    submit() {
      if (this.$refs.form) {
        this.$refs.form.validate((valid) => {
          const payload = {
            claims: {
              env: this.envOptions.find(v => v.name === this.form.env)?.value || 'DEV',
              domain: this.form.domain,
            }
          };

          if (valid) {
            if (!!this.form.subAccountUid) {
              payload.subAccountUid = this.form.subAccountUid;
              payload.subAccountId = this.subAccountList.find(x => x.SubAccountUid  === payload.subAccountUid).SubAccountId
            }

            this.$emit('submit', {
              formType: this.formType,
              payload
            });
            return true;
          }
          this.$message.error(this.$t('validations.required_fields'));
          return false;
        });
      }
      return false;
    },
  },
};
