//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapMutations, mapGetters } from 'vuex';
import Filters from './partials/Filters.vue';
import Table from './partials/Table.vue';
import Pagination from './partials/Pagination.vue';
import FormModal from './partials/SdkModal.vue';
import envOptions from '../../json/envOptions.json';

export default {
  name: 'Home',

  components: {
    Filters,
    Table,
    Pagination,
    FormModal,
  },

  data() {
    return {
      formShow: false,
      quickPrivateKey: '',
      copied: false,
      isPrivateDisplayed: false,
      paginationIndex: 1,
      // Pagination
      envOptions: envOptions,
      limit: 15,
    };
  },

  computed: {
    ...mapGetters({
      keyList: 'auth_sdk/getList',
      subAccountList: 'user/getSubAccounts',
      selectedKey: 'auth_sdk/getSelectedKey',
    }),
  },

  created() {
    this.getSubaccounts();
    this.getAllKeys();
    this.setIndex(1);
    this.setSelectedKey({});

    // Subject for Private Key retrieval
    const subAccountId = localStorage.getItem('sdkSubAccountId')
    if (subAccountId) {
      this.longPollPrivateKey(subAccountId)
    }
  },

  mounted() {
    window.addEventListener('localeChanged', () => {
      this.$i18n.locale = this.$getSelectedLocale();
    });
  },

  methods: {
    ...mapGetters({
      loading: 'auth_sdk/getLoading',
    }),
    ...mapMutations({
      setFilters: 'auth_sdk/SET_FILTERS',
      setIndex: 'auth_sdk/SET_PAGINATION_INDEX',
      setSelectedKey: 'auth_sdk/SET_SELECTED',
    }),

    ...mapActions({
      getSubaccounts: 'user/fetchSubaccounts',
      getAllKeys: 'auth_sdk/fetchKeys',
      createSDK: 'auth_sdk/createSDK',
      longPollPrivate: 'auth_sdk/longPollPrivate',
      updateSDK: 'auth_sdk/updateSDK',
    }),

    longPollPrivateKey(subAccountId) {
      const lpp = setInterval(async () => {
        const { key } = await this.longPollPrivate(subAccountId) || {};
        if (key) {
          this.isPrivateDisplayed = true;
          this.quickPrivateKey = key.privateKey;
          return clearInterval(lpp);
        }
      }, 5000);
    },

    copyToClipboard() {
      if (window && window.document && window.document.execCommand) {
        const apiKeyTextarea = document.getElementById('apiKey');
        apiKeyTextarea.select();
        document.execCommand('copy');
        localStorage.removeItem('sdkSubAccountId');

        this.copied = true;
        this.quickPrivateKey = '';
      }
    },

    submitForm(form = {}) {
      const { formType, payload } = form;

      if (formType === 'CREATE') { // Create
        this.createSDK(payload).then((data) => {
          const { subAccountId } = data;
          localStorage.setItem('sdkSubAccountId', subAccountId);
          this.$message.success(this.$t('success.saved_for_later', { value: 'SDK API Key Pair' }));
          this.setSelectedKey({});
          this.formShow = false;
          this.longPollPrivateKey(subAccountId);
        }).catch((err) => {
          const { data } = err;
          this.$showError(this, err);
        });
      } else { // Edit webhook
        this.$confirm(this.$t('confirmations.modify', { item: this.$t('webhooks.webhook') }), this.$t('actions.modify'), {
          confirmButtonText: this.$t('actions.yes'),
          cancelButtonText: this.$t('actions.cancel'),
          type: 'warning',
        }).then(() => {
          this.updateWebhook(payload).then(() => {
            this.$message.success(this.$t('success.updated', { value: 'webhook' }));
            // Set selected webhook to empty
            this.setSelectedKey({});
            this.formShow = false;
          }).catch((err) => {
            this.$showError(this, err);
          });
        }).catch(() => 0);
      }
    },

    updateFilter(payload = {}) {
      this.setFilters(payload);

      // Update webhooks data list
      this.getWebhooks().then(() => {
        // this.$message.success(this.$t('success.updated', { value: 'webhook' }));
      }).catch((err) => {
        this.$showError(this, err);
      });
    },

    closeModal() {
      // Set selected webhook to empty
      this.setSelectedKey({});
      this.formShow = false;
    },

    showWebhookModal(payload = '') {
      this.setSelectedKey(payload);
      this.formShow = true;
    },

    updateStatus(payload = {}) {
      this.updateWebhook({
        ...payload,
        enabled: !payload.enabled,
      }).then(() => {
        this.$message.success(this.$t('success.updated', { value: 'webhook' }));
      }).catch((err) => {
        this.$showError(this, err);
      });
    },

    getPaginatedData() {
      let p;

      if (this.keyList.length > this.limit) {
        p = this.paginationIndex * this.limit;
        return this.keyList.slice(p, p + 1 * this.limit);
      }

      return this.keyList;
    },

    retrieveSubAccounts() {
      return this.subAccountList;
    },
    retrieveEnvOptions() {
      return this.envOptions;
    },

    // prev pagination
    prevPage() {
      this.setIndex(this.paginationIndex - 1);
    },

    // next pagination
    nextPage() {
      this.setIndex(this.paginationIndex + 1);
    },
  },
};
