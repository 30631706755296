<template>
  <div class="l-container relative">
    <div class="flex p-4 pb-8 -mb-4 rounded-t bg-grey-lightest">
      <div class="w-full">
        <!-- date range Select -->
        <span class="text-xs block mb-2">{{ $t('fields.subaccount') }}</span>
        <!-- <el-select
          :value="filters.subAccount"
          :disabled="loading"
          class="w-full"
          size="small"
          filterable
          @change="v => updateFilter({ subaccount: v })"
        >
          <el-option
            v-for="subaccount in [{ SubAccountId: '' }, ...subaccountList]"
            :key="subaccount.SubAccountId"
            :label="subaccount.SubAccountId || $t('webhooks.filters[1]')"
            :value="subaccount.SubAccountId"
          />
        </el-select> -->
        <el-select
          :value="filters.env"
          :disabled="loading"
          class="w-full"
          size="small"
          filterable
          @change="v => updateFilter({ env: v })"
        >
          <el-option
            v-for="env in envOptions"
            :key="env.value"
            :label="env.label"
            :value="env.value"
          />
        </el-select>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'Filters',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    filters: {
      env: String,
      default: () => {},
    },

    subaccountList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    const envOptions = [{
      label: 'All',
      value: ''
    }, {
      label: 'DEVELOPMENT',
      value: 'DEV'
    }, {
      label: 'STAGING',
      value: 'STG'
    }, {
      label: 'PRODUCTION | LIVE',
      value: 'PROD'
    }]
  },

  created() {
    // this.env = this.filters.env;
  },

  methods: {
    updateFilter(v) {
      this.$emit('update-filter', v);
    },
  },
};
</script>
