//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Filters',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    filters: {
      env: String,
      default: () => {},
    },

    subaccountList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    const envOptions = [{
      label: 'All',
      value: ''
    }, {
      label: 'DEVELOPMENT',
      value: 'DEV'
    }, {
      label: 'STAGING',
      value: 'STG'
    }, {
      label: 'PRODUCTION | LIVE',
      value: 'PROD'
    }]
  },

  created() {
    // this.env = this.filters.env;
  },

  methods: {
    updateFilter(v) {
      this.$emit('update-filter', v);
    },
  },
};
