<template>
  <div class="mb-20">
    <h1 class="text-xl font-serif mb-10">
      {{ $t('sidebar_menu[13]') }}
    </h1>
    <div class="flex -mx-3">
      <div class="w-full px-3 mb-5">
        <div class="mb-10 items-center">
          <span class="text-sm text-grey block mr-10">
            {{ $t('auth_sdk.default[1]') }}
          </span>
        </div>
        <div v-if="quickPrivateKey" class="w-full border border-gray-300 rounded-lg shadow-sm p-3 text-left">
            <p class="mb-4 text-gray-700 text-sm">{{ $t('auth_sdk.api_key_note')}}</p>
            <div class="relative">
            <div class="tooltip-container">
                <i v-if="!copied" @click="copyToClipboard()" class="icon-novo icon-novo-copy absolute mt-2 right-2 transform translate-y-1/2 bg-blue-500 text-black rounded-full p-2 hover:text-blue-600 hover:cursor-pointer focus:cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-300"></i> <!-- Font Awesome copy icon -->
                <i v-else class="icon-novo icon-novo-check absolute mt-2 right-2 transform translate-y-1/2 bg-blue-500 text-black rounded-full p-2 hover:text-blue-600 hover:cursor-pointer focus:cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-300"></i> <!-- Font Awesome copy icon -->
                <span class="tooltip-text">Copy to clipboard</span>
            </div>
                <input type="text" id="apiKey" class="w-full pl-8 p-3 pr-8 border border-gray-300 rounded-lg text-gray-800 resize-none" readonly :value="quickPrivateKey"></textarea>
            </div>
        </div>
        <Table
          :data="getPaginatedData()"
          :env-options="retrieveEnvOptions()"
          @update-status="updateStatus"
          @edit="showWebhookModal"
          @create-sdkApi="() => formShow = true"
        />
        <Pagination
          v-if="keyList.length"
          :index="index"
          :limit="limit"
          :data="keyList"
          @prev-page="prevPage"
          @next-page="nextPage"
        />
      </div>
      <div class="main__aside px-3">
          <div class="pt-20 -mt-20 sticky pin-t">
            <el-button
              size="small"
              class="mb-5 w-full"
              type="primary"
              @click.prevent="() => formShow = true"
            >
              {{ $t('auth_sdk.button.create') }}
            </el-button>
            <!-- <Filters
              :loading="loading"
              :filters="filters"
              :subaccount-list="this.subAccounts"
              :type-list="() => {}"
              @update-filter="updateFilter"
            /> -->
          </div>
      </div>
    </div>
    <FormModal
      :wh-visible="formShow"
      :sub-account-list="retrieveSubAccounts()"
      :env-options="retrieveEnvOptions()"
      :loading="loading"
      @close-modal="closeModal"
      @submit="submitForm"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import Filters from './partials/Filters.vue';
import Table from './partials/Table.vue';
import Pagination from './partials/Pagination.vue';
import FormModal from './partials/SdkModal.vue';
import envOptions from '../../json/envOptions.json';

export default {
  name: 'Home',

  components: {
    Filters,
    Table,
    Pagination,
    FormModal,
  },

  data() {
    return {
      formShow: false,
      quickPrivateKey: '',
      copied: false,
      isPrivateDisplayed: false,
      paginationIndex: 1,
      // Pagination
      envOptions: envOptions,
      limit: 15,
    };
  },

  computed: {
    ...mapGetters({
      keyList: 'auth_sdk/getList',
      subAccountList: 'user/getSubAccounts',
      selectedKey: 'auth_sdk/getSelectedKey',
    }),
  },

  created() {
    this.getSubaccounts();
    this.getAllKeys();
    this.setIndex(1);
    this.setSelectedKey({});

    // Subject for Private Key retrieval
    const subAccountId = localStorage.getItem('sdkSubAccountId')
    if (subAccountId) {
      this.longPollPrivateKey(subAccountId)
    }
  },

  mounted() {
    window.addEventListener('localeChanged', () => {
      this.$i18n.locale = this.$getSelectedLocale();
    });
  },

  methods: {
    ...mapGetters({
      loading: 'auth_sdk/getLoading',
    }),
    ...mapMutations({
      setFilters: 'auth_sdk/SET_FILTERS',
      setIndex: 'auth_sdk/SET_PAGINATION_INDEX',
      setSelectedKey: 'auth_sdk/SET_SELECTED',
    }),

    ...mapActions({
      getSubaccounts: 'user/fetchSubaccounts',
      getAllKeys: 'auth_sdk/fetchKeys',
      createSDK: 'auth_sdk/createSDK',
      longPollPrivate: 'auth_sdk/longPollPrivate',
      updateSDK: 'auth_sdk/updateSDK',
    }),

    longPollPrivateKey(subAccountId) {
      const lpp = setInterval(async () => {
        const { key } = await this.longPollPrivate(subAccountId) || {};
        if (key) {
          this.isPrivateDisplayed = true;
          this.quickPrivateKey = key.privateKey;
          return clearInterval(lpp);
        }
      }, 5000);
    },

    copyToClipboard() {
      if (window && window.document && window.document.execCommand) {
        const apiKeyTextarea = document.getElementById('apiKey');
        apiKeyTextarea.select();
        document.execCommand('copy');
        localStorage.removeItem('sdkSubAccountId');

        this.copied = true;
        this.quickPrivateKey = '';
      }
    },

    submitForm(form = {}) {
      const { formType, payload } = form;

      if (formType === 'CREATE') { // Create
        this.createSDK(payload).then((data) => {
          const { subAccountId } = data;
          localStorage.setItem('sdkSubAccountId', subAccountId);
          this.$message.success(this.$t('success.saved_for_later', { value: 'SDK API Key Pair' }));
          this.setSelectedKey({});
          this.formShow = false;
          this.longPollPrivateKey(subAccountId);
        }).catch((err) => {
          const { data } = err;
          this.$showError(this, err);
        });
      } else { // Edit webhook
        this.$confirm(this.$t('confirmations.modify', { item: this.$t('webhooks.webhook') }), this.$t('actions.modify'), {
          confirmButtonText: this.$t('actions.yes'),
          cancelButtonText: this.$t('actions.cancel'),
          type: 'warning',
        }).then(() => {
          this.updateWebhook(payload).then(() => {
            this.$message.success(this.$t('success.updated', { value: 'webhook' }));
            // Set selected webhook to empty
            this.setSelectedKey({});
            this.formShow = false;
          }).catch((err) => {
            this.$showError(this, err);
          });
        }).catch(() => 0);
      }
    },

    updateFilter(payload = {}) {
      this.setFilters(payload);

      // Update webhooks data list
      this.getWebhooks().then(() => {
        // this.$message.success(this.$t('success.updated', { value: 'webhook' }));
      }).catch((err) => {
        this.$showError(this, err);
      });
    },

    closeModal() {
      // Set selected webhook to empty
      this.setSelectedKey({});
      this.formShow = false;
    },

    showWebhookModal(payload = '') {
      this.setSelectedKey(payload);
      this.formShow = true;
    },

    updateStatus(payload = {}) {
      this.updateWebhook({
        ...payload,
        enabled: !payload.enabled,
      }).then(() => {
        this.$message.success(this.$t('success.updated', { value: 'webhook' }));
      }).catch((err) => {
        this.$showError(this, err);
      });
    },

    getPaginatedData() {
      let p;

      if (this.keyList.length > this.limit) {
        p = this.paginationIndex * this.limit;
        return this.keyList.slice(p, p + 1 * this.limit);
      }

      return this.keyList;
    },

    retrieveSubAccounts() {
      return this.subAccountList;
    },
    retrieveEnvOptions() {
      return this.envOptions;
    },

    // prev pagination
    prevPage() {
      this.setIndex(this.paginationIndex - 1);
    },

    // next pagination
    nextPage() {
      this.setIndex(this.paginationIndex + 1);
    },
  },
};
</script>
<style scoped>
#apiKey {
  background-color: #F8AE1A;
}
.icon-novo-copy:hover {
  cursor: pointer;
}

.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-container .tooltip-text {
    visibility: hidden;
    width: 120px; /* Adjust width as needed */
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the button */
    left: 50%;
    margin-left: -30px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 12px; /* Adjust font size as needed */
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}
</style>
