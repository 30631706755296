import http from '../../../../utils/http';

export default {
  // Fetch subaccount list
  fetchSubaccounts: async ({ commit }) => {
    commit('SET_LOADING', true);

    try {
      const { data } = await http.v2.get('auth-sdk/subaccounts');

      commit('SET_SUBACCOUNT_LIST', data.subAccounts);
      return data.subAccounts;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};
