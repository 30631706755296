// import { Message } from 'element-ui';
import http from '../../../../utils/http';

export default {
  // Fetch webhooks data from api
  fetchKeys: async ({ commit, state }) => {
    commit('SET_LOADING', true);
    const {
      subaccount, // eslint-disable-line
      type, // eslint-disable-line
    } = state.filters;

    try {
      const { data } = await http.v2.get('auth-sdk/keys');
      commit('SET_SDK_API_LIST', data.keys);
      commit('SET_PAGINATION_INDEX', 0);

      return data.keys;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      commit('SET_LOADING', false);

      return Promise.reject(err);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  longPollPrivate: async ({ commit }, subAccountId) => {
    try {
      const { data } = await http.sdk.get(`sdk/get-key?subAccountId=${subAccountId}`);

      return data.key;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },

  // Create SDK API Key
  createSDK: async ({ commit, dispatch }, payload = {}) => {
    commit('SET_LOADING', true);
    try {
      const { data } = await http.sdk.post('/auth/generate-keypair', payload);
      // Fetch Keys
      setTimeout(dispatch('fetchKeys'), 6000);
      return data;
    } catch (err) {
      commit('SET_LOADING', false);
      commit('API_ERROR', err, { root: true });

      return Promise.reject(err);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  // Edit SDK API Config
  updateSDK: async ({ commit, dispatch }, payload = {}) => {
    const {
      subAccountId,
      type,
      url,
      httpAuthorization,
      enabled,
      keyValue: key,
    } = payload;

    // Set loading to specific webhook item
    commit('UPDATE_DICTIONARY_VALUE', {
      key,
      payload: { loading: true },
    });

    const p = {
      subAccountId,
      type,
      url,
      httpAuthorization,
      enabled,
    };

    try {
      await http.v2.post('webhooks', p);
      // Message.success('Updated webhook');
      // Fetch new webhook data
      dispatch('fetchWebhooks', false);

      return { success: true };
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      // Set loading to specific webhook item
      commit('UPDATE_DICTIONARY_VALUE', {
        key,
        payload: { loading: false },
      });
    }
  },

};
