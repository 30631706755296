<template>
  <div>
    <el-table
      :loading="loading"
      :data="data"
      size="small"
      fit
    >
      <template slot="empty">
        <div class="text-center p-10 text-xs text-black leading-loose">
          <h1 class="text-black text-base mb-5 font-semibold">
            {{ $t('auth_sdk.table.no_data') }}
          </h1>
          <span class="text-sm text-grey block mb-5">
            {{ $t('auth_sdk.table.create_new') }}
          </span>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-plus"
            @click="$emit('create-sdkApi')"
          >
            {{ $t('auth_sdk.button.create') }}
          </el-button>
        </div>
      </template>
      <!-- SubAccountId column -->
      <el-table-column
        v-slot="scope"
        :label="$t('auth_sdk.table.column[0]')"
        width="150px"
        fixed
      >
        <div
          class="text-xs text-black font-bold"
        >
          {{ scope.row.SubAccountId || '' }}
        </div>
      </el-table-column>

      <!-- Access Key ID column -->
      <el-table-column
        v-slot="scope"
        :label="$t('auth_sdk.table.column[1]')"
        width="500px"
        show-overflow-tooltip
      >
        <div
          class="text-xs inline-block text-black rounded-sm wh-tag"
        >
          {{ scope.row.AccessKeyId || '' }}
        </div>
      </el-table-column>

      <!-- Public Key column -->
      <!-- <el-table-column
        v-slot="scope"
        :label="$t('auth_sdk.table.column[2]')"
        width="250px"
        show-overflow-tooltip
      >
        <span
          class="text-xs truncate text-black no-underline"
          target="_blank"
        >
          {{ (scope.row.PublicKey || '') }}
        </span>
      </el-table-column> -->

      <!-- ENV column -->
      <el-table-column
        v-slot="scope"
        :label="$t('auth_sdk.table.column[3]')"
        width="100px"
        :class="text-center"
      >
        <span
          class="text-xs truncate text-black no-underline text-center"
        >
          {{ envOptions.find(x => x.value === scope.row.Environment).name.toUpperCase() || 'SANDBOX' }}
        </span>
      </el-table-column>

      <!-- Domain token column -->
      <el-table-column
        v-slot="scope"
        :label="$t('auth_sdk.table.column[4]')"
        width="150px"
        align="right"
      >
        <span
          class="text-xs truncate text-blue no-underline"
          target="_blank">
          {{ scope.row.Origin || '' }}
        </span>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import envOptions from '../../../json/envOptions.json';
import { mapGetters } from 'vuex';

export default {
  name: 'Table',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Array,
      default: () => [],
    }
  },

  data() {
    return {
      envOptions,
      loading: false,
    };
  },

  methods: {
    ...mapGetters({
      loading: 'auth_sdk/getLoading',
    }),
    handleCommand(c) {
      switch (c.command) {
      case 'EDIT':
        this.$emit('edit', c.content);
        break;
      case 'ENABLE':
        this.$emit('update-status', c.content);
        break;
      case 'DELETE':
        this.$emit('delete', c.content);
        break;
      default:
        break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wh-tag {
  line-height: 1;
  padding: 5px 7px 3px;
  text-transform: uppercase;
  text-align: center;
  word-break: break-word;
  white-space: normal;
  line-height: 1;
  display: inline-block;
}
</style>