<template>
  <el-dialog
    :title="modalTitle()"
    :visible.sync="whVisible"
    width="40%"
    top="20px"
    destroy-on-close
    custom-class="px-10"
    :loading="loading"
    @close="closeModal"
    @open="resetForm"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="formRules"
      autocomplete="off"
    >
      <!-- Subaccount input -->
      <div class="block">
        <p class="mb-1 text-sm font-medium">
          {{ $t('fields.subaccount') }}
        </p>
        <div>
          <p class="text-grey-dark mb-2 text-xs">
            {{ $t('auth_sdk.header.sub_account') }}
          </p>
          <el-form-item>
            <el-select
              v-model="form.subAccountUid"
              :placeholder="$t('auth_sdk.modal[1]')"
              size="small"
              :style="{width: '60%'}"
              filterable
            >
            <el-option 
              :key="0"
              :label="$t('auth_sdk.modal[2]')"
              :value="0"
            />
              <el-option
                v-for="subAccount in subAccountList"
                :key="subAccount.SubAccountUid"
                :label="subAccount.SubAccountId"
                :value="subAccount.SubAccountUid"
              />
            </el-select>
          </el-form-item>
        </div>
      </div>

      <!-- Environment Specific -->
      <div class="block">
        <p class="mb-1 text-sm font-medium">
          {{ $t('auth_sdk.modal[3]') }}
        </p>
        <div v-if="formType === 'CREATE'">
          <p class="text-grey-dark mb-3 text-xs">
            {{ $t('auth_sdk.modal[4]') }}
          </p>
          <el-form-item prop="type">
            <el-radio-group
              v-model="form.env"
            >
              <el-radio
                v-for="env in envOptions"
                v-if="env.enabled"
                :key="env.value"
                :label="env.name"
                class="mb-2 inline items-center"
              >
                {{ env.name }}
                <!-- <span class="text-xs text-grey ml-1">
                  ({{ $t(`webhooks.desc.${typeOptions[type].description || '-'}`) }})
                </span> -->
              </el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <p
          v-else
          class="mb-5 text-sm inline-block font-bold font-bold text-black rounded-sm wh-modal-tag"
        >
          {{ form.type }}
          <span
            v-if="!Object.keys(typeOptions).includes(form.type)"
            class="text-sm text-red-dark capitalize font-normal ml-2"
          >
            ⚠️{{ $t('webhooks.index[3]').toUpperCase() }}
          </span>
        </p>
      </div>

      <!-- URL input -->
      <div class="block">
        <p class="mb-1 text-sm font-medium">
          {{ $t('auth_sdk.modal[5]') }}
        </p>
        <p class="text-grey-dark mb-2 text-xs">
          {{ $t('auth_sdk.modal[6]') }}
        </p>
        <el-form-item prop="url">
          <el-input
            v-model="form.domain"
            placeholder="https://company-url.com"
            size="small"
            class="w-full"
            type="url"
          />
        </el-form-item>
      </div>

    </el-form>

    <!-- Buttons -->
    <div class="mt-5 flex justify-end">
      <div>
        <el-button
          type="default"
          @click="closeModal"
        >
          {{ $t('actions.cancel') }}
        </el-button>
        <el-button
          type="primary"
          @click="submit"
        >
          {{ formType === 'CREATE' ? $t('auth_sdk.button.create') : $t('actions.save_changes') }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'SdkModal',

  props: {
    defaultFormValue: {
      type: Object,
      default: () => {},
    },

    envOptions: {
      type: Array,
      default: () => [],
    },

    subAccountList: {
      type: Array,
      default: () => [],
    },

    whVisible: {
      type: Boolean,
      default: false,
    }

  },

  data() {
    const self = this;

    return {
      formType: 'CREATE', // or EDIT

      emptyForm: {
        subAccountUid: '',
        env: '',
        domain: '',
      },

      form: {},

      formRules: {
        subAccountUid: [
          { required: true, message: self.$t('validations.select', { value: self.$t('fields.subaccount') }), trigger: 'change' },
        ],

        env: [
          { required: true, message: self.$t('validations.select', { value: self.$t('auth_sdk.modal[4]') }), trigger: 'change' },
        ],

        domain: [
          { required: true, message: self.$t('validations.required', { value: self.$t('auth_sdk.modal[5]') }), trigger: 'change' },
          { type: 'url', message: self.$t('validations.valid', { value: self.$t('auth_sdk.modal[5]') }), trigger: 'change' },
        ],
      },
    };
  },

  methods: {
    ...mapGetters({
      loading: 'auth_sdk/getLoading',
    }),
    modalTitle() {
      return this.$t('auth_sdk.header.new_sdk');
      // return this.formType === 'CREATE' ? this.$t('webhooks.modal[13]') : this.$t('webhooks.modal[14]');
    },

    closeModal() {
      if (this.$refs.form) {
        this.$refs.form.resetFields();
      }

      this.$emit('close-modal');
    },

    resetForm() {
      this.formType = Object.keys(this.defaultFormValue || {}).length ? 'EDIT' : 'CREATE';

      // Set form default to empty
      if (this.formType === 'CREATE') {
        this.form = { ...this.form, ...this.emptyForm };
      } else { // Set form to defaultFormValue
        this.form = { ...this.defaultFormValue };
      }

      // Remove validation messages after setting form datas
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
    },

    submit() {
      if (this.$refs.form) {
        this.$refs.form.validate((valid) => {
          const payload = {
            claims: {
              env: this.envOptions.find(v => v.name === this.form.env)?.value || 'DEV',
              domain: this.form.domain,
            }
          };

          if (valid) {
            if (!!this.form.subAccountUid) {
              payload.subAccountUid = this.form.subAccountUid;
              payload.subAccountId = this.subAccountList.find(x => x.SubAccountUid  === payload.subAccountUid).SubAccountId
            }

            this.$emit('submit', {
              formType: this.formType,
              payload
            });
            return true;
          }
          this.$message.error(this.$t('validations.required_fields'));
          return false;
        });
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wh-modal-tag {
  line-height: 1;
  padding: 5px 7px 3px;
  text-transform: uppercase;
  text-align: center;
  word-break: break-word;
  white-space: normal;
  line-height: 1;
  display: inline-block;
}

.wh-version-tag {
  padding: 3px 4px 2px;
  text-transform: uppercase;
  text-align: center;
  word-break: break-word;
  white-space: normal;
  line-height: 1;
  display: inline-block;
  font-size: 0.6em;
}
</style>
