/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    subAccounts: [],
    loading: false,
  },

  getters: {
    getSubAccounts: st => st.subAccounts,
  },

  mutations: {
    SET_SUBACCOUNT_LIST(state, subAccounts) {
      state.subAccounts = subAccounts;
    },

    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },

  actions,
};
